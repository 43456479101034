import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import { useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { ImageThumbnail, NewsListItem, StringField } from '@/typings/model';
import Button from '@/components/Button';
import { StoreDispatch, useTypedSelector } from '@/store/store';
import { getParamAsString } from '@/utils/common';
import {
  getAdditionsProductionFiltersByAlias,
  saveAdditionProductionFiltersByAliasThunk,
} from '@/store/reducers/productionFilter';
import { getNewsListByShop } from '@/services/requests';
import { colors } from '@/constants/theme';
import { selectNewsList } from '@/store/reducers/newsList';

import NewsBanner from '../NewsBanner';
import NewsList from '../NewsList';
import SectionSelect from '../Filters/SectionSelect';
import ShoppingCentersSelect from '../Filters/ShoppinCentersSelect';

export interface InitialValueProps {
  section: string;
  shop: number;
}

type Props = {
  bannerImage?: ImageThumbnail;
  bannerLink: StringField;
  bannerTitle: StringField;
};

function Main({ bannerTitle, bannerImage, bannerLink }: Props) {
  const newsList = useTypedSelector(selectNewsList);
  const tabletMedia = useMedia('(max-width: 1259.9px)');

  const [activeNews, setActiveNews] = useState<number>(8);
  const [activeNewsList, setActiveNewsList] = useState<Array<NewsListItem>>([]);
  const [isVisible, setVisible] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(true);

  const router = useRouter();
  const dispatch = useDispatch<StoreDispatch>();

  const filters = useTypedSelector((state) =>
    getAdditionsProductionFiltersByAlias(
      state,
      getParamAsString('/news/category')
    )
  );

  const [activeSection, setActiveSection] = useState<string>(
    filters?.section ? filters.section : '/'
  );

  const [activeShop, setActiveShop] = useState<number>(
    filters?.shop ? Number(filters.shop) : 0
  );

  useEffect(() => {
    setActiveShop(filters?.shop ? Number(filters.shop) : 0);
  }, [filters?.shop]);

  function getNews(
    newsList: Array<NewsListItem>,
    activeSection: string,
    activeShop: number
  ) {
    if (activeSection === 'torgovyy-centr' && activeShop !== 0) {
      setStatus(false);
      getNewsListByShop(`filter[trade-center]=${activeShop}`)
        .then((response) => {
          if (!response.data) return;
          setActiveNewsList(response.data);
        })
        .finally(() => {
          setStatus(true);
        });
    } else if (activeSection === 'korona-mebel' && activeShop !== 0) {
      setStatus(false);
      getNewsListByShop(`filter[korona-mebel]=${activeShop}`)
        .then((response) => {
          if (!response.data) return;
          setActiveNewsList(response.data);
        })
        .finally(() => {
          setStatus(true);
        });
    } else {
      const listNew: Array<NewsListItem> = [];
      newsList.forEach((newsItem) => {
        if (activeSection === '/') {
          listNew.push(newsItem);
        } else {
          newsItem.categories.forEach((category) => {
            if (category.urlAlias === activeSection) {
              listNew.push(newsItem);
            }
          });
        }
      });

      setActiveNewsList(listNew);
    }
  }

  useEffect(() => {
    dispatch(
      saveAdditionProductionFiltersByAliasThunk(
        getParamAsString('/news/category'),
        {
          section: activeSection,
          shop: activeShop,
        }
      )
    );

    if (activeSection === '/') {
      router.push({
        pathname: getParamAsString(`/news`),
      });
    } else {
      router.push({
        pathname: getParamAsString(`/news/category/${activeSection}`),
        query: {
          shop: activeShop ? activeShop : [],
        },
      });
    }
  }, [activeShop, activeSection]);

  useEffect(() => {
    getNews(newsList, activeSection, activeShop);
  }, [activeShop, activeSection]);

  function setNewsList(list: Array<NewsListItem>, activeNews: number) {
    return list.slice(0, activeNews);
  }

  function getNewsLength(list: Array<NewsListItem>) {
    return list.length;
  }

  function addMore(count: number) {
    setActiveNews(count + 8);
  }

  return (
    <Component>
      <Inner>
        <Top>
          <Title>Новости</Title>
        </Top>
        <Filter>
          <FilterCol>
            <SectionSelect
              activeSection={activeSection}
              setActiveSection={(value: string) => setActiveSection(value)}
              setVisible={(value: boolean) => setVisible(value)}
              setActiveShop={(value: number) => setActiveShop(value)}
            />
          </FilterCol>
          {isVisible && (
            <FilterCol>
              <ShoppingCentersSelect
                setActiveShop={(value: number) => setActiveShop(value)}
                activeShop={activeShop}
                childrenPagesTemplate={
                  activeSection === 'torgovyy-centr'
                    ? 'shopping-center-single'
                    : 'korona-dom'
                }
              />
            </FilterCol>
          )}
        </Filter>
        <Top>
          <Left>
            {/**NEWS LIST**/}
            <NewsList
              newsList={setNewsList(activeNewsList, activeNews)}
              status={status}
              bannerImage={bannerImage}
              bannerLink={bannerLink}
              bannerTitle={bannerTitle}
            />
          </Left>
          {bannerImage && !tabletMedia && (
            <Right>
              <BannerWrapper>
                <NewsBanner
                  bannerImage={bannerImage}
                  bannerLink={bannerLink}
                  bannerTitle={bannerTitle}
                />
              </BannerWrapper>
            </Right>
          )}
        </Top>
        <Bottom>
          <ButtonWrapper isHidden={getNewsLength(activeNewsList) <= activeNews}>
            <Button variant="orange" onClick={() => addMore(activeNews)}>
              Показать еще
            </Button>
          </ButtonWrapper>
        </Bottom>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Inner = styled.div`
  padding: 92px 0 240px;

  ${media.laptop(css`
    padding: 64px 0 180px;
  `)}

  ${media.tablet(css`
    padding: 30px 0 150px;
  `)}

  ${media.mobile(css`
    padding: 20px 0 130px;
  `)}
`;

const Top = styled.div`
  display: flex;

  ${media.tablet(css`
    flex-direction: column;
  `)}
`;

const Title = styled.h2`
  margin: 0 20px 0 0;
  font-weight: 500;
  font-size: 50px;
  line-height: 58px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 48px;
    line-height: 56px;
    margin: 0 16px 0 0;
  `)}

  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const Bottom = styled.div``;

const Left = styled.div`
  flex: 1 1 auto;
  margin-right: 32px;

  ${media.laptop(css`
    margin-right: 24px;
  `)}

  ${media.tablet(css`
    margin-right: 0;
  `)}
`;

const Right = styled.div`
  flex: 0 0 376px;
  max-width: 376px;

  ${media.laptop(css`
    flex: 0 0 384px;
    max-width: 384px;
  `)}
`;

const BannerWrapper = styled.div`
  margin-top: -40px;
  position: sticky;
  top: 60px;

  ${media.laptop(css`
    top: 40px;
  `)}
`;

const ButtonWrapper = styled.div<{ isHidden?: boolean }>`
  margin-top: 100px;

  ${(props) =>
    props.isHidden
      ? css`
          display: none;
        `
      : ''}
  button {
    width: 100%;
    padding: 21px 0;
    border-radius: 2px;
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
  }

  ${media.laptop(css`
    margin-top: 80px;

    button {
      padding: 24px 0;
      font-size: 24px;
      line-height: 32px;
    }
  `)}

  ${media.tablet(css`
    margin-top: 30px;

    button {
      padding: 17px 0;
      font-size: 20px;
      line-height: 26px;
    }
  `)}

  ${media.mobile(css`
    margin-top: 50px;

    button {
      padding: 15px 0;
      font-size: 16px;
      line-height: 20px;
    }
  `)}
`;

const Filter = styled.div`
  display: flex;
  margin: 29px -19px;

  ${media.laptop(css`
    margin: 24px -15px;
  `)}

  ${media.mobile(css`
    margin: 30px -15px;
  `)}
`;

const FilterCol = styled.div`
  padding: 0 19px;

  ${media.laptop(css`
    padding: 0 15px;
  `)}
`;

export default Main;
