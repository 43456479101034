import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { NewsListItem } from '@/typings/model';
import Picture from '@/components/Picture';
import Link from '@/components/Link';

type Props = {
  newsListItem: NewsListItem;
};

function NewsCard({ newsListItem }: Props) {
  const { urlAlias, datetime, title, excerpt, coverImage } = newsListItem;
  return (
    <Component>
      {urlAlias ? <ComponentLink to={`/news/${urlAlias}`} /> : null}
      <Inner>
        <Left>
          <ImageWrapper>
            {coverImage ? (
              <Picture
                mobileSmall={convertThumbnailToPictureImage(coverImage)}
              />
            ) : null}
          </ImageWrapper>
        </Left>
        <Right>
          <Title>{title}</Title>
          {datetime ? (
            <Date>
              {datetime
                .replace(/(\d+)-(\d+)-(\d+)/, '$3-$2-$1')
                .replace(/-/g, '.')
                .substring(0, 10)}
            </Date>
          ) : null}
          <Text>{excerpt}</Text>
        </Right>
      </Inner>
    </Component>
  );
}

const Inner = styled.div`
  display: flex;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const Left = styled.div`
  flex: 0 0 376px;
  max-width: 376px;
  margin-right: 32px;

  ${media.laptop(css`
    flex: 0 0 282px;
    max-width: 282px;
    margin-right: 24px;
  `)}

  ${media.tablet(css`
    margin-right: 37px;
  `)}
  
  ${media.mobile(css`
    flex: auto;
    max-width: 100%;
    margin-right: 0;
  `)}
`;

const Right = styled.div`
  flex: 1 1 auto;

  ${media.mobile(css`
    margin-top: 20px;
  `)}
`;

const Title = styled.h3`
  margin: 0 0 16px;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black400};
  transition: all 0.3s linear;

  ${media.laptop(css`
    max-width: 450px;
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    max-width: none;
    font-size: 20px;
    line-height: 24px;
  `)}
  
  ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  `)}
`;

const Date = styled.span`
  display: block;
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black500};

  ${media.laptop(css`
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
  `)}
`;

const Text = styled.p`
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black500};

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${media.laptop(css`
    font-size: 16px;
    line-height: 24px;
  `)}

  ${media.tablet(css`
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    font-size: 12px;
    line-height: 20px;
  `)}
`;

const ImageWrapper = styled.div`
  width: 100%;
  position: relative;
  background: ${colors.gray};
  border-radius: 32px;
  overflow: hidden;

  ${media.laptop(css`
    border-radius: 24px;
  `)}

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-bottom: 69%;

    ${media.laptop(css`
      padding-bottom: 67.4%;
    `)}

    ${media.mobile(css`
      padding-bottom: 62.5%;
    `)}
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const ComponentLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const Component = styled.div`
  padding: 56px 0;
  border-bottom: 2px solid ${colors.grayLight};
  position: relative;

  ${media.laptop(css`
    padding: 39px 0;
    border-bottom: 1px solid ${colors.grayLight};
  `)}

  ${media.tablet(css`
    padding: 30px 0;
  `)}

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  &:hover {
    ${Title} {
      color: ${colors.orange};
    }
  }
`;

export default NewsCard;
