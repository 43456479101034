import React from 'react';
import styled from 'styled-components';

import Breadcrumbs from '@/components/Breadcrumbs';
import { useLayout } from '@/components/Layout/Layout.hooks';
import { BreadCrumbs } from '@/typings/common';
import { setBreadCrumbsList } from '@/utils/common';
import useCurrentPage from '@/hooks/useCurrentPage';
import { NewsPageType } from '@/typings/model';
import { useTypedSelector } from '@/store/store';
import { selectNewsPageTemplate } from '@/store/reducers/newsPageTemplate';

import Main from './components/Main';

function News() {
  const page = useCurrentPage<NewsPageType>();
  const newsPage = useTypedSelector(selectNewsPageTemplate);
  const { setThemePage } = useLayout();
  setThemePage('');

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList({
    // title: page?.title ?? '',
    title: 'Новости',
    path: page?.path ?? '',
  });

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />
      <Main
        bannerImage={
          page?.templateFields.image ?? newsPage?.templateFields.image
        }
        bannerLink={
          page?.templateFields?.link ?? newsPage?.templateFields?.link ?? ''
        }
        bannerTitle={
          page?.templateFields?.title || newsPage?.templateFields?.title || ''
        }
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default News;
