import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { ImageThumbnail, StringField } from '@/typings/model';
import Picture from '@/components/Picture';
import Link from '@/components/Link';
import { media } from '@/utils/mixin';

type Props = {
  bannerImage?: ImageThumbnail;
  bannerLink: StringField;
  bannerTitle: StringField;
};

function NewsBanner({ bannerImage, bannerTitle, bannerLink }: Props) {
  return (
    <Component>
      {bannerLink ? <ComponentLink to={bannerLink} /> : null}
      {bannerImage ? (
        <Background>
          <Picture mobileSmall={convertThumbnailToPictureImage(bannerImage)} />
        </Background>
      ) : null}
      <Inner>
        <Text>{bannerTitle}</Text>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  margin-bottom: 20px;
  transition: all 0.3s linear;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0 8px 18px rgb(127 131 164 / 30%);
  }
`;

const ComponentLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.grayLight};

  div,
  picture,
  img {
    width: 100%;
    height: 100%;
  }

  ${media.tablet(css`
    img {
      object-fit: cover;
    }
  `)}
`;

const Inner = styled.div`
  position: relative;
  min-height: 640px;
  padding: 40px;
  display: flex;
  align-items: center;

  ${media.tablet(css`
    min-height: 200px;
    padding: 27px 60px;
    justify-content: center;
  `)}

  ${media.mobile(css`
    min-height: 460px;
    padding: 20px;
  `)}
`;

const Text = styled.p`
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  color: ${colors.white};

  ${media.mobile(css`
    font-size: 28px;
    line-height: 34px;
  `)}
`;

export default NewsBanner;
