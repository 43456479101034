import React from 'react';
import styled, { css } from 'styled-components';

import { generateNumberArray, useMedia } from '@tager/web-core';

import NewsCard from '@/modules/News/components/NewsCard';
import { ImageThumbnail, NewsListItem, StringField } from '@/typings/model';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import NewsBanner from '@/modules/News/components/NewsBanner';

import NewsCardPlaceholder from '../NewsCardPlaceholder';

type Props = {
  newsList: Array<NewsListItem>;
  status: boolean;
  bannerImage?: ImageThumbnail;
  bannerLink?: StringField;
  bannerTitle?: StringField;
};

function NewsList({
  newsList,
  status,
  bannerImage,
  bannerLink,
  bannerTitle,
}: Props) {
  const tabletMedia = useMedia('(max-width: 1259.9px)');
  return (
    <Component>
      <Inner>
        {!status ? (
          generateNumberArray(1).map((num) => <NewsCardPlaceholder key={num} />)
        ) : newsList.length ? (
          newsList?.map((newsListItem, index) => {
            return index === 3 && tabletMedia ? (
              <>
                <BannerWrapper>
                  <NewsBanner
                    bannerImage={bannerImage}
                    bannerLink={bannerLink ?? ''}
                    bannerTitle={bannerTitle ?? ''}
                  />
                </BannerWrapper>
                <NewsCard key={index} newsListItem={newsListItem} />
              </>
            ) : (
              <NewsCard key={index} newsListItem={newsListItem} />
            );
          })
        ) : (
          <NotFoundText>В этом разделе нет новостей</NotFoundText>
        )}
      </Inner>
    </Component>
  );
}

const Component = styled.div``;

const Inner = styled.div``;

const NotFoundText = styled.span`
  padding-top: 3vh;
  margin: 0 auto 0 auto;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 20px;
    line-height: 26px;
  `)}
`;

const BannerWrapper = styled.div`
  margin: 30px 0;

  ${media.mobile(css`
    margin: 20px 0;
  `)}
`;

export default NewsList;
