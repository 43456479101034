import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import DropDownSelect from '@/components/DropDownSelect';
import { OptionType } from '@/components/DropDownSelect/DropDownSelect';
import { NewsSectionItem } from '@/typings/model';
import { useTypedSelector } from '@/store/store';
import { selectNewsSectionList } from '@/store/reducers/newsSection';

interface Props {
  setActiveSection: (value: string) => void;
  activeSection: string;
  setVisible: (value: boolean) => void;
  setActiveShop: (value: number) => void;
}

function SectionSelect({
  activeSection,
  setActiveSection,
  setVisible,
  setActiveShop,
}: Props) {
  const sectionList = useTypedSelector(selectNewsSectionList);

  const [sectionOptions, setSectionOptions] = useState<Array<OptionType>>(
    createSectionOptions(sectionList)
  );
  const [selectedSection, setSelectedSection] = useState<OptionType>();

  function createSectionOptions(list: Array<NewsSectionItem>) {
    const optionList = [{ label: 'Все новости', value: '/' }];
    list.forEach((listItem) => {
      let newItem: OptionType = { label: '', value: '' };
      newItem.label = listItem.name ?? '';
      newItem.value = listItem.urlAlias ?? '';
      optionList.push(newItem);
    });

    return optionList;
  }

  const sortSectionValue = useMemo<OptionType>(
    () =>
      sectionOptions.find(
        (option) => option.value === selectedSection?.value
      ) ?? sectionOptions[0],
    [selectedSection, sectionOptions]
  );

  function handlesSectionChange(selectedOption: OptionType | null): void {
    if (selectedOption) {
      setSelectedSection(selectedOption);

      if (selectedOption.value === '/') {
        setActiveSection('/');
      } else {
        sectionList.forEach((section) => {
          if (section.urlAlias === selectedOption.value) {
            setActiveSection(section.urlAlias);
          }
        });
      }
    }
  }

  useEffect(() => {
    setSectionOptions(createSectionOptions(sectionList));
  }, [sectionList]);

  useEffect(() => {
    setActiveShop(0);
    if (
      activeSection === 'torgovyy-centr' ||
      activeSection === 'korona-mebel'
    ) {
      setVisible(true);
    } else {
      setVisible(false);
    }

    sectionOptions.forEach((section) => {
      if (section.value === activeSection) {
        setSelectedSection(section);
      }
    });
  }, [activeSection]);

  return (
    <Component>
      <DropDownSelect
        placeholder="Выбрать..."
        options={sectionOptions}
        value={sortSectionValue}
        onChange={handlesSectionChange}
      />
    </Component>
  );
}

const Component = styled.div``;

export default SectionSelect;
