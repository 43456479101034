import React from 'react';
import styled, { css } from 'styled-components';

import { placeholderAnimation } from '@tager/web-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

function NewsCardPlaceholder() {
  return (
    <Component>
      <Inner>
        <Left>
          <ImageWrapper />
        </Left>
      </Inner>
    </Component>
  );
}

const customPlaceholder = css`
  animation: ${placeholderAnimation} 2s linear;
  animation-iteration-count: infinite;
  background-image: linear-gradient(
    to right,
    rgb(0 0 0/ 3%) 0,
    rgb(0 0 0 / 3%) 15%,
    rgb(241 241 241 / 90%) 30%
  );
  background-color: var(--bg-color-placeholder);
  background-size: 1200px 100%;
`;

const Component = styled.div`
  padding: 56px 0;
  border-bottom: 2px solid ${colors.grayLight};
  position: relative;

  ${media.laptop(css`
    padding: 39px 0;
    border-bottom: 1px solid ${colors.grayLight};
  `)}

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const Inner = styled.div`
  display: flex;
`;

const Left = styled.div`
  flex: 0 0 376px;
  max-width: 376px;

  ${media.laptop(css`
    flex: 0 0 282px;
    max-width: 282px;
  `)}
`;

const ImageWrapper = styled.div`
  width: 100%;
  position: relative;
  ${customPlaceholder};
  border-radius: 32px;
  overflow: hidden;

  ${media.laptop(css`
    border-radius: 24px;
  `)}

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-bottom: 69%;

    ${media.laptop(css`
      padding-bottom: 67.4%;
    `)}
  }
`;

export default NewsCardPlaceholder;
