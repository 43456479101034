import { useEffect, useState } from 'react';

import { getPagesByTemplate } from '@/services/requests';
import { PageByTemplateType } from '@/typings/model';

function useCurrentChildrenPages(template: string) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Array<PageByTemplateType>>([]);

  useEffect(() => {
    setLoading(true);

    getPagesByTemplate(template)
      .then(({ data }) => {
        setData(data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [template]);

  return { isLoading, data };
}

export default useCurrentChildrenPages;
