import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import DropDownSelect from '@/components/DropDownSelect';
import { OptionType } from '@/components/DropDownSelect/DropDownSelect';
import { PageByTemplateType } from '@/typings/model';
import useCurrentChildrenPages from '@/hooks/useCurrentChildrenPages';

interface Props {
  setActiveShop: (value: number) => void;
  activeShop: number;
  childrenPagesTemplate?: string;
}

function ShoppingCentersSelect({
  activeShop,
  setActiveShop,
  childrenPagesTemplate,
}: Props) {
  const { data: shoppingCenterList } = useCurrentChildrenPages(
    childrenPagesTemplate || 'shopping-center-single'
  );

  const [shopOptions, setShopOptions] = useState<Array<OptionType>>(
    createShopOptions(shoppingCenterList)
  );
  const [selectedShop, setSelectedShop] = useState<OptionType>();

  function createShopOptions(list: Array<PageByTemplateType>) {
    const optionList = [{ label: 'Все торговые центры', value: '0' }];
    list.forEach((listItem) => {
      let newItem: OptionType = { label: '', value: '' };
      newItem.label = listItem.title ?? '';
      newItem.value = String(listItem.id) ?? '';
      optionList.push(newItem);
    });

    return optionList;
  }

  const sortSectionValue = useMemo<OptionType>(
    () =>
      shopOptions.find((option) => option.value === selectedShop?.value) ??
      shopOptions[0],
    [selectedShop, shopOptions]
  );

  function handlesShopChange(selectedOption: OptionType | null): void {
    if (selectedOption) {
      setSelectedShop(selectedOption);

      if (selectedOption.value === '0') {
        setActiveShop(0);
      } else {
        shoppingCenterList.forEach((shop) => {
          if (shop.id === Number(selectedOption.value)) {
            setActiveShop(shop.id);
          }
        });
      }
    }
  }

  useEffect(() => {
    setShopOptions(createShopOptions(shoppingCenterList));
  }, [shoppingCenterList]);

  useEffect(() => {
    shopOptions.forEach((shop) => {
      if (shop.value === String(activeShop)) {
        setSelectedShop(shop);
      }
    });
  }, [activeShop, shopOptions]);

  return (
    <Component>
      <DropDownSelect
        placeholder="Выбрать..."
        options={shopOptions}
        value={sortSectionValue}
        onChange={handlesShopChange}
      />
    </Component>
  );
}

const Component = styled.div``;

export default ShoppingCentersSelect;
